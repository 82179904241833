import urql, {
  type SSRData,
  Client,
  fetchExchange,
  ssrExchange,
  gql,
} from '@urql/vue';
import {
  cacheExchange,
  type OptimisticMutationConfig,
  type UpdatesConfig,
} from '@urql/exchange-graphcache';
import { devtoolsExchange } from '@urql/devtools';
// import {
//   UpdateMasterSpecializationInput,
//   UpdateUserAvatarInput,
//   UpdateUserInput,
// } from '#shared/gql/graphql';
import schema from '@/gql/introspection.json';
import {
  defineNuxtPlugin,
  useRequestHeaders,
  useRuntimeConfig,
  useState,
} from 'nuxt/app';
import { useCartId } from '@/features/cart';

export default defineNuxtPlugin((nuxt) => {
  const { apiGraphqlUrl } = useRuntimeConfig().public;
  const urqlState = useState<SSRData | undefined>('urql-data');
  const isClient = !!process.client;
  const ssr = ssrExchange({
    isClient,
    initialState: isClient ? urqlState.value : undefined,
  });
  const headers = useRequestHeaders();
  const fetchOptions: RequestInit = isClient
    ? { credentials: 'include' }
    : {
        headers: {
          Cookie: headers.cookie?.toString(),
          'X-Forwarded-For': headers['x-forwarded-for'],
          'X-Real-Ip': headers['x-real-ip'],
        },
      };

  const cartIdStore = useCartId();
  const updates: UpdatesConfig = {
    Mutation: {
      addPartnerServiceToCart: (result, _args, cache) => {
        const cartService = result.addPartnerServiceToCart;
        if (!cartService) return;
        const cartId = cartService?.cart.id;
        cartIdStore.updateCartId(cartId);

        const entity = { __typename: 'Cart', id: cartId };
        const cartServices = cache.resolve(entity, 'cartServices') ?? [];
        cache.link(entity, 'cartServices', [
          ...(cartServices as any),
          cartService,
        ]);
      },
      deleteCartService: (_result, args, cache) => {
        cache.updateQuery(
          {
            query: gql`
              query DeleteCartService_CartQuery($id: Int) {
                cart(id: $id) {
                  id
                  cartServices {
                    id
                  }
                }
              }
            `,
            variables: { id: cartIdStore.cartId },
          },
          (data) => {
            data.cart.cartServices = data.cart.cartServices.filter(
              (cartService) => cartService.id !== args.cartServiceId
            );
            return data;
          }
        );
      },
      deleteWorkPlaceServicesFromCart: (_result, args, cache) => {
        cache.updateQuery(
          {
            query: gql`
              query DeleteWorkPlaceServicesFromCart_CartQuery($id: Int) {
                cart(id: $id) {
                  id
                  cartServices {
                    id
                    workPlace {
                      id
                    }
                  }
                }
              }
            `,
            variables: { id: cartIdStore.cartId },
          },
          (data) => {
            data.cart.cartServices = data.cart.cartServices.filter(
              (cartService) => cartService.workPlace.id !== args.workPlaceId
            );
            return data;
          }
        );
      },
    },
  };

  const optimistic: OptimisticMutationConfig = {};

  nuxt.vueApp.use(
    urql,
    new Client({
      url: apiGraphqlUrl,
      fetchOptions,
      exchanges: [
        devtoolsExchange,
        cacheExchange({
          schema,
          updates,
          optimistic,
        }),
        ssr,
        fetchExchange,
      ],
    })
  );

  if (!isClient)
    nuxt.hooks.hook('app:rendered', () => {
      const data = ssr.extractData();
      urqlState.value = data;
    });
});
