import { isNumericString } from '@glow/shared/utils/string';
import { useLocalStorage } from '@vueuse/core';
import { defineStore, skipHydrate } from 'pinia';

const CART_ID_STORAGE_KEY = 'cart_id';

export const useCartId = defineStore('cartId', () => {
  const cartId = useLocalStorage<number | null>(CART_ID_STORAGE_KEY, null, {
    serializer: {
      read: (value) => (isNumericString(value) ? Number.parseInt(value) : null),
      write: (value) => String(value),
    },
  });
  const isLoggedIn = useIsLoggedIn();
  const hasCart = computed(() => isLoggedIn.value || !!cartId.value);

  function updateCartId(id: number) {
    cartId.value = !isLoggedIn.value ? id : undefined;
  }
  return { cartId: readonly(skipHydrate(cartId)), updateCartId, hasCart };
});
