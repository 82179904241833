import validate from "/home/node/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@16.18.93_@unocss+reset@0.60.3_eslint@8.57.0_flo_jss2qzajcgjg3gndrxhujdrkq4/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/home/node/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@16.18.93_@unocss+reset@0.60.3_eslint@8.57.0_flo_jss2qzajcgjg3gndrxhujdrkq4/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "detect-catalog-city": () => import("/home/node/app/src/middleware/detect-catalog-city.ts"),
  auth: () => import("/home/node/app/node_modules/.pnpm/@glow+shared@2.0.0_typescript@4.9.5/node_modules/@glow/shared/middleware/auth.ts"),
  "master-only": () => import("/home/node/app/node_modules/.pnpm/@glow+shared@2.0.0_typescript@4.9.5/node_modules/@glow/shared/middleware/master-only.ts"),
  "support-only": () => import("/home/node/app/node_modules/.pnpm/@glow+shared@2.0.0_typescript@4.9.5/node_modules/@glow/shared/middleware/support-only.ts"),
  "moderator-only": () => import("/home/node/app/node_modules/.pnpm/@glow+shared@2.0.0_typescript@4.9.5/node_modules/@glow/shared/middleware/moderator-only.ts"),
  "admin-only": () => import("/home/node/app/node_modules/.pnpm/@glow+shared@2.0.0_typescript@4.9.5/node_modules/@glow/shared/middleware/admin-only.ts")
}