import revive_payload_client_q5eF8F69sh from "/home/node/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@16.18.93_@unocss+reset@0.60.3_eslint@8.57.0_flo_jss2qzajcgjg3gndrxhujdrkq4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_7nMDeUziqk from "/home/node/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@16.18.93_@unocss+reset@0.60.3_eslint@8.57.0_flo_jss2qzajcgjg3gndrxhujdrkq4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_muKXbba51U from "/home/node/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@16.18.93_@unocss+reset@0.60.3_eslint@8.57.0_flo_jss2qzajcgjg3gndrxhujdrkq4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_lx21zhXIvk from "/home/node/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@16.18.93_@unocss+reset@0.60.3_eslint@8.57.0_flo_jss2qzajcgjg3gndrxhujdrkq4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_km73XBRWwn from "/home/node/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@16.18.93_@unocss+reset@0.60.3_eslint@8.57.0_flo_jss2qzajcgjg3gndrxhujdrkq4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_Zpal0NxmK1 from "/home/node/app/node_modules/.pnpm/@pinia+nuxt@0.4.11_typescript@4.9.5_vue@3.4.21/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/node/app/.nuxt/components.plugin.mjs";
import prefetch_client_o43GkVGiJm from "/home/node/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@16.18.93_@unocss+reset@0.60.3_eslint@8.57.0_flo_jss2qzajcgjg3gndrxhujdrkq4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_IWhb0t2i6n from "/home/node/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@16.18.93_@unocss+reset@0.60.3_eslint@8.57.0_flo_jss2qzajcgjg3gndrxhujdrkq4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import head_0tN8WUbtKU from "/home/node/app/src/plugins/head.ts";
import urql_v08Sn7DSyh from "/home/node/app/src/plugins/urql.ts";
import http_S2L7YuQ2MK from "/home/node/app/node_modules/.pnpm/@glow+shared@2.0.0_typescript@4.9.5/node_modules/@glow/shared/plugins/http.ts";
import vue_query_ezYDnnJRYP from "/home/node/app/node_modules/.pnpm/@glow+shared@2.0.0_typescript@4.9.5/node_modules/@glow/shared/plugins/vue-query.ts";
import locale_ZqGDPm7wQ9 from "/home/node/app/node_modules/.pnpm/@glow+shared@2.0.0_typescript@4.9.5/node_modules/@glow/shared/plugins/locale.ts";
export default [
  revive_payload_client_q5eF8F69sh,
  unhead_7nMDeUziqk,
  router_muKXbba51U,
  payload_client_lx21zhXIvk,
  check_outdated_build_client_km73XBRWwn,
  plugin_vue3_Zpal0NxmK1,
  components_plugin_KR1HBZs4kY,
  prefetch_client_o43GkVGiJm,
  chunk_reload_client_IWhb0t2i6n,
  head_0tN8WUbtKU,
  urql_v08Sn7DSyh,
  http_S2L7YuQ2MK,
  vue_query_ezYDnnJRYP,
  locale_ZqGDPm7wQ9
]